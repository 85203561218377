.sidebar {
    height: 100vh;
    overflow: scroll;
}

.sidebar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidebar::-webkit-scrollbar {
    display: none;
}