.LoginContainer {
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
  height: 100vh;

  .btntheme,
  .btn-primary {
    background-color: #37162e;
    border: none;
    font-size: large;
  }

  .btntheme:hover {
    background-color: #a48a9d;
    color: #37162e;
    border: none;
    font-size: large;
  }

  /* This ensures that the container takes up the full viewport height */
  .Login {
    margin: 20px auto;
    width: 100%;

    .Login_Container {
      padding: 20px;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
      margin: auto;

      width: 40%;
      border-radius: 10px;

      h1 {
        text-align: center;
      }
    }
  }
}

.password-toggle-icon {
  position: absolute !important;
  top: 68%;
  left: 500px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
  color: #6c757d;
}
