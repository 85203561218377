.section_content{
    display: flex;
    flex-direction: row;

}

.section_content_buttons{
    position:absolute;
    bottom: 10px;
}

/* .file_content{
    width: 400px;
    height: 250px;
    background-color: red;
    justify-content: center;
    align-items: center;
} */